body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#5BC2E7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.portada {
  height: 100% !important;
}

.robotoTypo {
  font-family: 'Roboto', sans-serif !important;
}

.shadowText {
  text-shadow: 0px 0px 0px #000000;
}

.profile {
  background-size: cover;
  background-image: url(./img/profile.jpg);
  box-shadow: 0px 0px 10px #FFFFFF;;
}

.espacio {
  padding-top: 10vh;
  height: auto;
}

.size {
  min-height: 90vh;
}

.cursos {
  margin: auto;
}

.sizeDos {
  height: 6vh !important;
}
.html {
  min-width: 370px !important;
  min-height: 660px !important;
}

.fondo {

  background-color:#5BC2E7;
}

.letra {
  color: #FDFFFC;
}

.seccion {
  height: 0vh; 
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.boton1 {
  background-color: #FCD06B !important;
  color: #F7F6EE !important;
}
.boton2 {
  background-color: #99DEDF !important;
  color: #F7F6EE !important;
}
.boton3 {
  background-color: #6980C5 !important;
  color: #F7F6EE !important;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.titulo {
  font-family: 'Comfortaa', cursive;
}

@media screen and (max-width: 500px) {
  .live {
    height: 200px !important;
  }
}

@media screen and (max-width: 600px) {
  .live {
    height: 300px ;
  }
}

@media screen and (max-width: 800px) {
  .live {
    height: 400px ;
  }
}

@media screen and (min-width: 800px) {
  .live {
    height: 500px ;
  }
}